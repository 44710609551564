@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background: #e3e6f2;
    color: #333;
    line-height: 1.6;
}

h2 {
    font-size: 1.15em;
    font-weight: bold;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.app-header {
    color: white;
    padding: 1rem 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.app-header h1 {
    margin: 0;
    font-size: 1.8rem;
}

.main-content {
    flex-grow: 1;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.options {
    /* background-color: #f0f0f0; */
    border-radius: 8px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
}

.options.closed {
    max-height: 60px;
    overflow: hidden;
}

.options-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 1rem;
    cursor: pointer;
}

.options-title {
    margin: 0;
    text-align: center;
}

.options-toggle {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.options-content {
    padding: 0 20px 20px;
}

.option-group {
    margin-bottom: 15px;
}

.graph-builder,
.latex-output,
.pdf-preview {
    margin-bottom: 1.5rem;
}

.node-input,
.edge-input {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.btn-primary,
.btn-secondary {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn-primary {
    background-color: #0056b3;
    color: white;
}

.btn-primary:hover {
    background-color: #004494;
}

.btn-secondary {
    background-color: #6c757d;
    color: white;
}

.btn-secondary:hover {
    background-color: #5a6268;
}

.latex-textarea {
    width: 100%;
    height: 300px;
    margin-bottom: 1rem;
}

.button-group {
    display: flex;
    gap: 10px;
}

.switch[data-state='unchecked'] {
    background-color: #ccc !important;
}

.switch[data-state='unchecked']:hover {
    background-color: #999 !important;
}

.switch[data-state='checked'] {
    background-color: #2196f3 !important;
}

.switch[data-state='checked']:hover {
    background-color: #1976d2 !important;
}

.switch span {
    background-color: white !important;
}

footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 30px;
}

.buy-me-coffee {
    display: inline-block;
}

.buy-me-coffee img {
    width: 150px;
    height: auto;
}

.sub-option {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
}

.sub-option label {
    display: inline-block;
    width: 100px;
}

.sub-option input {
    width: calc(100% - 110px);
}

.error-message {
    color: #dc3545;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
}

.pdf-iframe {
    width: 100%;
    height: 500px;
    border: none;
}

@media (max-width: 768px) {
    .main-content {
        padding: 1rem;
    }

    .node-input,
    .edge-input {
        flex-direction: column;
    }
}
